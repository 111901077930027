<template>
	<div>
		<div class="home">
			<div id="workers" class="home__container">
				<div style="margin-bottom:5vh; margin-top:5vh;">
					<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
					<h1><span>j<em>u</em>mp</span> in...</h1>
					<p class="page-intro">Awesome opportunities and quick pay. Welcome to the best platform for flexible gig work.</p>
					<router-link :to="{name: 'login'}">
						<button class="btn btn__primary btn__mega mt-5">Create Your Profile</button>
					</router-link>
				</div>
			</div>
			<Jumpers />
			<WorkerBenefits />
			<VenueTypes />
			<div class="faq__wrapper">
				<h2>FAQs</h2>
				<div class="faq">
					<div class="question">
						<h4>How much do we get paid?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showPricing = !showPricing" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showPricing">Rates are different for each job and location. Rates are posted for each shift when you apply.</div>
				</div>
				<!-- <div class="faq">
					<div class="question">
						<h4>How quickly to we get paid?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showScreen = !showScreen" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showScreen">We've partnered with Branch to pay you as quickly as possible. Once we receive and verify your timesheets, that data is entered into our software and then loaded into Branch, where the cash is available right away in your app. In most cases, you will receive your payment within 24-72 hours. For larger or more complex events, it may take longer.</div>
				</div> -->
				<div class="faq">
					<div class="question">
						<h4>Am I required to have insurance?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showInsurance = !showInsurance" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showInsurance">You are required to maintain your own insurance, including but not limited to vehicle insurance and workers compensation insurance, in amounts equal to or greater than the minimum requirements as required by law in your area. In the event You are injured while providing Services under this Agreement, You acknowledge and understand that You will not be covered by any workers compensation insurance coverage that Jump Staffing may provide to its employees. <a href="/contractor">Learn more</a>.</div>
				</div>
				<div class="faq">
					<div class="question">
						<h4>Who do I contact if I have a question?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showManage = !showManage" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showManage">If you have a question about a specific shift, job, or event, please contact your shift coordinator directly. If you have a general question, please email support@jumpstaffing.com.</div>
				</div>
				<div class="faq">
					<div class="question">
						<h4>How are you different from other staffing apps?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showDifferent = !showDifferent" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showDifferent">Gig workers enjoy multiple platforms to find work and make a living. J<em>u</em>mp stands out for three reasons: our opportunities for work, our leadership team, and fast, accurate payments.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Jumpers from '@/components/Jumpers.vue'
import WorkerBenefits from '@/components/WorkerBenefits.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'workers',
	data() {
    return {
    	showPricing: false,
    	showInsurance: false,
    	showScreen: false,
    	showManage: false,
    	showDifferent: false,
    }
  },
	components: {
		Jumpers,
		WorkerBenefits,
		VenueTypes
	}
}
</script>